import { getResultData, getServerData, postServerData } from "../Helper/helper";
import * as Action from "../redux/resultReducer";



export const pushAnswer = (result) => async (dispatch) => {
  try {
    await dispatch(Action.pushResultAction(result));
  } catch (error) {
    console.log(error);
  }
};

export const updateResult = (index) => async (dispatch) => {
  try {
    await dispatch(Action.updateResultAction(index));
  } catch (error) {
    console.log(error);
  }
};
// Enter user data
export const usePublishResult = (resultData) => {
  const { username, result, quizAttempts } = resultData;
  (async () => {
    try {
      if (!username) throw new Error("No results");
      await postServerData(
        `${process.env.REACT_APP_SERVER_HOSTNAME}/api/result`,
        resultData,
        (data) => data
      );
    } catch (error) {
      console.log(error);
    }
  })();
};

// Get user data

export const useGetAuth = () => {
  (async () => {
    try {
      // if(!username) throw new Error("No results")
      const data = await getResultData(
        `${process.env.REACT_APP_SERVER_HOSTNAME}/api/result`);
        console.log(data);
        return data;
    } catch (error) {
      console.log(error);
    }
  })();
};
