import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setloggedUser } from "../redux/resultReducer";

function Pay() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [eMail, setEmail] = useState("");
  const Navigate = useNavigate();
  
  const tx_ref = `${firstName}-tx-${Math.random()}`;
  const public_key = "CHAPUBK_TEST-iZB9CLFQ1gQIp1rqIdNUrK7YQNFg8vqT";



 
  return (
    <div className="container-fluid">
      <div className="row">
        <h1 className="title text-light ">Payment Form</h1>
        <h3 className="title text-light h3 ">
          For Domestic users registration fee is only ETB 5
        </h3>
        <h3 className="title text-light h3 ">
          For International users registration fee is only $ 1
        </h3>

        <form id="form-pay">
          <input
            className="user-id"
            type="text"
            placeholder="firstName*"
            onChange={(e)=>setFirstName(e.target.value)}
          />
          <br />
          <br />
          <input
            className="user-id"
            type="text"
            placeholder="lastName*"
            onChange={(e)=>setlastName(e.target.value)}
          />
          <br />
          <br />
          <input
            className="password-id"
            type="password"
            placeholder="Password*"
            onChange={(e)=>setPassWord(e.target.value)}
          />
          <br />
          <br />
          <input
            className="email-id"
            type="e-mail"
            placeholder="e-mail*"
            onChange={(e)=>setEmail(e.target.value)}
          />
        </form>

        <form method="POST" action="https://api.chapa.co/v1/hosted/pay">
          <input type="hidden" name="public_key" value={public_key} />
          <input type="hidden" name="tx_ref" value={tx_ref} />
          <input type="hidden" name="amount" value="5" />
          <input type="hidden" name="currency" value="ETB" />
          <input type="hidden" name="email" value={eMail} />
          <input type="hidden" name="first_name" value={firstName} />
          <input type="hidden" name="last_name" value={lastName} />
          <input type="hidden" name="password" value={passWord} />
          <input
            type="hidden"
            name="description"
            value="Paying with Confidence with cha"
          />
          <input
            type="hidden"
            name="logo"
            value="https://chapa.link/asset/images/chapa_swirl.svg"
          />
          <input
            type="hidden"
            name="callback_url"
            value="https://example.com/callbackurl"
          />
          <input
            type="hidden"
            name="return_url"
            value="https://quizprizereg.onrender.com"
          />
          <input type="hidden" name="meta[title]" value="test" />

          <button
            className="container-fluid start btn btn-1"
            type="submit"
          >
            Pay to Register
          </button >
        </form>

        <div className="start">
          <Link className="btn" to={"/"}>
            Back to Login
          </Link>
        </div>
      </div>
      <footer id="footer">Developed by TNT</footer>
    </div>
  );
}

export default Pay;
