

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Action from "../redux/questionReducer";
import { getServerData } from "../Helper/helper";

// a custom-hook to fetch api data(from QuestionsData.js) and set value to the store
export const useFetchQuestions = () => {
  const dispatch = useDispatch();
  const [getData, setGetData] = useState({
    isLoading: false,
    apiData: [],
    serverError: null,
  });

  useEffect(() => {
    setGetData(prev => ({ ...prev, isLoadinhg: true }));
    (
      // fetch the data
      async () => {
        try {
          const [{questions, answers}] = await getServerData(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/questions`, (data)=>data )
          console.log({questions, answers})
          if (questions.length > 0) {
            setGetData(prev => ({ ...prev, isLoadinhg: false }));
            setGetData(prev => ({ ...prev, apiData: {questions, answers} }));
            // dispatch an action and update the state
            dispatch(Action.startExamAction({questions, answers}));
          } else {
            throw new Error("No question avaliable");
          }
        } catch (error) {
          setGetData((prev) => ({ ...prev, isLoadinhg: false }));
          setGetData((prev) => ({ ...prev, serverError: error }));
        }
      }
    )();
  }, [dispatch]);
  
return [getData, setGetData];
};

// a custom-hook to move next 

export const moveNextQuestion = ()=>async(dispatch)=>{
  try {
    dispatch(Action.moveNextActions())
  } catch (error) {
    console.log(error)
  }
}

// a custom-hook to move back
export const movePrevQuestion = ()=>async(dispatch)=>{
  try {
    dispatch(Action.movePrevActions())
  } catch (error) {
    console.log(error)
  }
}
