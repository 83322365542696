import React, { useEffect, useState } from "react";

// import custom-hook
import { useFetchQuestions } from "../customHook/FetchQuestions";
import { useDispatch, useSelector } from "react-redux";
import { updateResultAction } from "../redux/resultReducer";
import { updateResult } from "../customHook/SetResult";
function Questions({ onChecked }) {
  const [checked, setChecked] = useState(undefined);
  const {trace} = useSelector(state=>state.questions)
  const result = useSelector(state=>state.result.result)
  // useSelector(state=>console.log(state.result.auth));
  const dispatch = useDispatch()
  const [{ isLoading, apiData, serverError }] = useFetchQuestions();
  const singleQuestion = useSelector(
    (state) => state.questions.que[state.questions.trace] // uses the trace tracker as index to select the questions
  );
  useEffect(() => {
    // console.log("hi")
    // console.log({trace, checked})
    function disbaleBackButton(){
      window.history.forward();
    }
    disbaleBackButton();
    window.onload = disbaleBackButton();
    window.onpageshow = disbaleBackButton();
    window.onunload = disbaleBackButton();
    window.onpopstate = disbaleBackButton();
    dispatch(updateResult({trace, checked}))
  }, [checked]);

  const onSelect = (i) => {
    setChecked(i) 
    onChecked(i);
    dispatch(updateResult({trace, checked}))
  };

  if (isLoading) return <h3 className="text-light">isLoading</h3>;
  if (serverError)
    return <h3 className="text-light">{serverError || "unknown Error"}</h3>;

  return (
    <div className="container questions">
      <h2 className="text-light">{singleQuestion?.Question}</h2>

      <ul key={singleQuestion?.id}>
        {singleQuestion?.options.map((q, i) => (
          <li key={i}>
            <input
              type="radio"
              value={false}
              name="options"
              id={`q${i}-option`}
              onChange={() => onSelect(i)}
            />
            <label className="text-primary" htmlFor={`q${i}-option`}>
              {q}
            </label>
            <div className={`check ${result[trace] == i ? 'checked' : ''}`}></div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Questions;
