import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import axios from "axios";

// accepts an array(the result) as a parameter and filter out only answered questions length
export function attemptedQuestions(result) {
  return result.filter((r) => r !== undefined).length;
}

// compare the attempted results with the actual answers and filter out the indexes of the correctly answred questions and assign ten points to the values add them up
export function answeredQuestions(result, answers, point) {
  return result
    .map((element, i) => answers[i] === element)
    .filter((i) => i)
    .map((i) => point)
    .reduce((prev, curr) => prev + curr, 0);
}
// return if half of the total points is less than earned points (i.e. less than 50%)
export function flagResult(totalPoints, earnedPoints) {
  return (totalPoints * 50) / 100 <= earnedPoints; // returns boolean
}

// check user authentication component

export function CheckUser({ children }, loginUser) {
  const user = useSelector((state) => state.result.auth);
  // let check;
  // for(let i=0; i<auth.length; i++){
  //     if(auth[0][0]==loginUser[0]){
  //       check =true;
  //     }else{
  //       check = false;
  //     }
  //   }

  return user ? children : <Navigate to={"/"} replace={true}></Navigate>;
}

// export function AddUser({children}){
//     const addUser = useSelector(state=>state.result.auth);

// }

//  get question data from server
export async function getServerData(url, callback) {
  const data = await (await axios.get(url))?.data;
//   console.log(data);
  return callback ? callback(data) : data;
}
getServerData("http://localhost/api/questions");

//  post server data
export async function postServerData(url, result, callback) {
  const data = await (await axios.post(url, result))?.data;
  // console.log(data)
  return callback ? callback(data) : data;
}
postServerData("http://localhost/api/result");

// get result data from the server

export async function getResultData(url, callback) {
  const data = await (await axios.get(url))?.data;
//   console.log(data);
  return callback ? callback(data) : data;
}
getResultData("http://localhost/api/result");
