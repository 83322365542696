import React from "react";
import ReactDOM from "react-dom/client";
import "./Css/index.css";
import App from "./Component/App";
import store from "./redux/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // provide your redux store to the whole application
  <Provider store={store}> 
    <App />
  </Provider>
);
