import React, { useEffect } from "react";
import "../Css/Result.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAllAction } from "../redux/questionReducer";
import { resetResultAction, setTrial } from "../redux/resultReducer";
import {
  answeredQuestions,
  attemptedQuestions,
  flagResult,
} from "../Helper/helper";
import { usePublishResult } from "../customHook/SetResult";
import { event } from "jquery";

function Result() {
  const dispatch = useDispatch();
  const Navigate = useNavigate()

  // destructure the whole state
  const {
    questions: { que, answers },
    result: { result, auth, loggedUser, quizAttempts, count },
  } = useSelector((state) => state);

  useEffect(() => {
    function disbaleBackButton(){
      window.history.forward();
    }
    disbaleBackButton();
    window.onload = disbaleBackButton();
    window.onpageshow = disbaleBackButton();
    window.onunload = disbaleBackButton();
    window.onpopstate = function(){
      // window.location.reload(false);
      dispatch(resetAllAction());
      dispatch(resetResultAction());
      return Navigate("/", {replace : true})
    };
    window.addEventListener("beforeUnload", ()=>{
      event.preventDefault();
      event.retunValue = "";
    })
    // console.log(earnedPoints);
    // console.log(flag);
    // console.log(auth);
    // console.log(loggedUser)
    // console.log(quizAttempts)
    // console.log(count)
  });

  const totalPoints = que.length * 10;
  const attempts = attemptedQuestions(result);
  const earnedPoints = answeredQuestions(result, answers, 10);
  const flag = flagResult(totalPoints, earnedPoints);
  const format = (time) => {
    let hours = Math.floor((time / 60 / 60) % 24);
    let minutes = Math.floor((time / 60) % 60);
    let seconds = Math.floor(time % 60);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds
  };

  // store user and result on the server
  usePublishResult({
    result,
    username: auth,
    attempts,
    points: earnedPoints,
    achived: flag ? "Passed" : "Failed",
    quizAttempts,
    count 
  });
  // console.log({
  //   result,
  //   username: auth,
  //   attempts,
  //   points: earnedPoints,
  //   achived: flag ? "Passed" : "Failed",
  //   quizAttempts ,
  //   count
    

  // });

  const onRestart = () => {
    // console.log('restarted')
    dispatch(resetAllAction());
    dispatch(resetResultAction());
    return Navigate("/", { replace: true });
  
  };

  return (
    <div className="container-fluid">
      <h1 className="title text-light">Quiz Result</h1>

      <div className="result flex-center">
        <div className="flex">
          <span>Username:</span>
          <span className="bold">
            {loggedUser[0]}
            </span>
        </div>
        <div className="flex">
          <span>Total Questions:</span>
          <span className="bold">
            {que.length < 10 ? `0${que.length}` : que.length}
          </span>
        </div>
        <div className="flex">
          <span>Total Questions Attempted:</span>
          <span className="bold">
            {attempts < 10 ? `0${attempts}` : attempts}
          </span>
        </div>
        <div className="flex">
          <span>Total Earned Points:</span>
          <span className="bold">{earnedPoints}</span>
        </div>
        <div className="flex">
          <span>Total Quiz Points:</span>
          <span className="bold">{totalPoints}</span>
        </div>
        <div className="flex">
          <span>Result Percentage:</span>
          <span className="bold">{`${
            (earnedPoints / totalPoints) * 100
          }%`}</span>
        </div>
        <div className="flex">
          <span>Total Time Elapsed:</span>
          <span className="bold">{format(count)}</span>
        </div>
        <div className="flex">
          <span>Remarks:</span>
          <span className="bold">{flag ? "Passed" : "Failed"}</span>
        </div>
      </div>
      <div className="start">
        <Link className="btn"  onClick={()=>onRestart}>
          Back to Home
        </Link>
      </div>
    </div>
  );
}

export default Result;
