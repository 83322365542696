import { createSlice } from "@reduxjs/toolkit";

export const resultReducer = createSlice({
    name : 'result',
    initialState : {
        userId :   null,
        passWord : null,
        auth : null,
        result : [] ,
        loggedUser : null,
        quizAttempts : null,
        count : null
    },
    reducers : {// you can declare multiple reducers(action handlers) here
        setUserId : (state, action)=>{ // reducer that sets the userId to the selected user  
            state.userId = action.payload
        },
        setPassWord :(state, action)=>{
            state.passWord = action.payload
        },
        setAuth : (state, action)=>{
            state.auth = action.payload;
           
            //  state.auth.push(action.payload);
                
            
        },
        setloggedUser : (state, action)=>{
            state.loggedUser = action.payload;
        },
      
        pushResultAction :(state, action)=>{
            state.result.push(action.payload)
        },
        updateResultAction : (state, action)=>{
            const { trace, checked } = action.payload;
            state.result.fill(checked, trace, trace + 1)

        },
        resetResultAction : (state, action)=>{
            return {
                // auth : null,
                result : [] 
        }       
        },
        setTrial : (state, action)=>{
            state.quizAttempts = action.payload;
        },
        setCounter : (state, action)=>{
            state.count = action.payload;
        }
       
        
    }
})

export const {setUserId, setPassWord, setAuth, setTrial, setloggedUser, setCounter , pushResultAction, resetResultAction, updateResultAction} = resultReducer.actions;
export default resultReducer.reducer