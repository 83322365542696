import React, { useEffect, useState } from "react";
import Questions from "./Questions";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  moveNextQuestion,
  movePrevQuestion,
} from "../customHook/FetchQuestions";
import { pushAnswer } from "../customHook/SetResult";
import { setloggedUser } from "../redux/resultReducer";
import Timer from "./Timer";

function Quiz() {
  const [check, setChecked] = useState(undefined);
  const result = useSelector((state) => state.result.result);
  const logged = useSelector((state)=>state.result.loggedUser);
  const auth = useSelector((state)=>state.result.auth);
  
  const { que, trace } = useSelector((state) => state.questions);
  var value = "";
  const dispatch = useDispatch();
  // console.log(auth)
  if(logged == null) dispatch(setloggedUser(auth));
  // console.log(logged)

  const onNext = () => {
    if (trace < que.length) {
      dispatch(moveNextQuestion());
      if (result.length <= trace) {
        dispatch(pushAnswer(check));
      }
    }
    setChecked(undefined)
  };



  const onPrev = () => {
    if (trace > 0) {
      dispatch(movePrevQuestion());
    }
  };

  function onChecked(che) {
    setChecked(che);
    // console.log(che);
  }

  // if(trace==que.length-1){
  //   value = "Submit"
  // }else{
  //   value = "Next"
  // }

  if (result.length && result.length >= que.length) {
    return <Navigate to={"/result"} replace="true"></Navigate>;
  }

  return (
    <div className="container-fluid">
       <Timer />
      <h1 className="title text-light ">Quizzes</h1>
      <Questions onChecked={onChecked} />
      <div className="grid">
        {trace > 0 ? (
          <button className="btn prev" onClick={onPrev}>
            Previous
          </button>
        ) : (
          <div></div>
        )}
        <button className="btn next" onClick={onNext}>
          {/* { value } */}
          {trace == que.length - 1 ? "Submit" : "Next"}
        </button>
      </div>
    </div>
  );
}

export default Quiz;
