import React, { useEffect, useRef, useState } from "react";
import "../Css/Timer.css"
import { useDispatch, useSelector } from "react-redux";
import { setCounter } from "../redux/resultReducer";

function Timer() {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(true);
  const count = useSelector(state=>state.result.count);
  const dispatch = useDispatch();

  const timer = useRef();

  useEffect(() => {
    if(running){
        timer.current = setInterval(()=>{
            setTime(prev => prev + 1);
        }, 100)
        
    }
    
  }, [running]);
//   console.log(time);

// console.log(count);
dispatch(setCounter(time));


  const format = (time) => {
    let hours = Math.floor((time / 60 / 60) % 24);
    let minutes = Math.floor((time / 60) % 60);
    let seconds = Math.floor(time % 60);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds
  };

  return (
    <div className="container">
      <p className="timer">Timer</p>
      <p className="timer">{format(time)}</p>
    </div>
  );
}

export default Timer;
