import {combineReducers, configureStore} from "@reduxjs/toolkit"
import  questionReducer  from "./questionReducer"
import  resultReducer  from "./resultReducer"

// call reducers
const rootReducer = combineReducers({ // combines multiple reducers in to one to create a store
    questions : questionReducer, // the first reducer assigned to questions property
    result : resultReducer, // the second reducer assigned to result property
})

// create store with the reducers
export default configureStore({reducer : rootReducer})

