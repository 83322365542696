import "../Css/App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Main from "./Main";
import Quiz from "./Quiz";
import Result from "./Result";
import { CheckUser } from "../Helper/helper";
import Registration from "./Registration";
import { useEffect } from "react";
import Timer from "./Timer";
import Pay from "./Pay";


// Router controller object
const myRouter = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/pay",
    element: (
        <Pay />
    ),
  },
  {
    path: "/quiz",
    element: (
        <Quiz />
    ),
  },
  {
    path: "/result",
    element: (
        <Result />
    ),
  },
  {
    path: "/register",

    element: <Registration />,
  },
]);
function App() {

  useEffect(() => {
 
    function disbaleBackButton(){
      window.history.forward();
    }
    disbaleBackButton();
    window.onload = disbaleBackButton();
    window.onpageshow = disbaleBackButton();
    window.onunload = disbaleBackButton();
    window.onpopstate = disbaleBackButton();
  
  }, []);
  
  return (
    <>
   
      <RouterProvider router={myRouter} />
    </>
  );
}

export default App;
