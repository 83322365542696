import { createSlice } from "@reduxjs/toolkit";


//createSlice- creates a state with an initialvalue, an action and a reducer that handles it
export const questionReducer = createSlice({
    name : 'questions', //name of your reducer
    initialState :{ // your state
       que : [], //all the questions
       answers : [], //all the answers
       trace : 0 // tracks where the user is(on which question)
    },
    reducers :{
        startExamAction : (state, action)=>{ //the reducer and action creator function(i.e. startExampAction)
            let {questions, answers} = action.payload // add what the user did(new action)
            
            return {
                ...state, // current state
                que :  questions,
                answers 
            }
        },
        moveNextActions : (state, action)=>{
            return{
                ...state,
                trace : state.trace + 1
            }
        },
        movePrevActions : (state, action)=>{
            return{
                ...state,
                trace : state.trace - 1
            }
        },
        resetAllAction : (state, action)=>{
            return {
                que : [], //all the questions
                answers : [], //all the answers
                trace : 0
            }
        }


    }
})

export  const {startExamAction, moveNextActions, movePrevActions, resetAllAction} = questionReducer.actions; // export the action to be dispatched
export default questionReducer.reducer; // the reducer that handles the dispatched action