import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery"
import "../Css/Main.css";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert2";
import {
  setPassWord,
  setUserId,
  setAuth,
  setloggedUser,
  setTrial,
} from "../redux/resultReducer";
import {
  CheckUser,
  answeredQuestions,
  attemptedQuestions,
  flagResult,
} from "../Helper/helper";
import { usePublishResult } from "../customHook/SetResult";

function Main() {
  const [user, setUser] = useState([]);
  const [quizTrial, setQuizTrial] = useState([]);
  const dispatch = useDispatch();
  const [passWord, setMyPassWord] = useState([]);
  const Navigate = useNavigate();
  const inputRefUser = useRef(null);
  const inputRefPassword = useRef(null);

  useEffect(() => {

    function disbaleBackButton(){
      window.history.forward();
    }
    disbaleBackButton();
    window.onload = disbaleBackButton();
    window.onpageshow = disbaleBackButton();
    window.onunload = disbaleBackButton();
    window.onpopstate = function(){
      return Navigate("/", {replace : true})
    };

    async function fetchData() {
      await fetch(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/result`)
        .then((res) => res.json())
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            user.push(data[i].username);
            quizTrial.push([data[i]._id, data[i].quizAttempts]);
            // passWord.push(data[i].username[1]);
          }
        })
        .catch((error) => console.log(error));
    }
    fetchData();
    
  }, []);

 
  
  // console.log(user);
  // console.log(quizTrial);
  function startQuiz() {
    if (inputRefUser.current?.value && inputRefPassword.current?.value) {
      const loginUser = [
        inputRefUser.current?.value,
        inputRefPassword.current?.value,
      ];
      // console.log(loginUser);
      
      for (let i = 0; i < user.length; i++) {
        console.log(user[i][0]);
        if (user[i][0].toLowerCase() === loginUser[0].toLowerCase()) {
          if (user[i][1].toLowerCase() === loginUser[1].toLowerCase()) {
            swal.fire( "User verified. You have already tried this quiz." ," The winner will be announced shortly. Another quiz will be provided after that.", "info");
            dispatch(
              setloggedUser([
                inputRefUser.current?.value,
                inputRefPassword.current?.value,
              ])
            );
            
            return Navigate("/", { replace: true });
          
        } else {
            swal.fire("", "Please enter the correct password", "warning"  );
            return Navigate("/", { replace: true });
          }
        }
      }

      // console.log("No user detected!");
      swal.fire("No user detected! ","Please Register!" , "warning");
      return Navigate("/pay", { replace: true });
    } else {
      swal.fire("","Please enter your username and password correctly!", "warning");
      return Navigate("/", { replace: true });
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <h1 className="title text-light ">Quiz Competition</h1>

        <ol>
          <li className="li1">
           Register once and compete in unlimited quiz contests
          </li>
          <li>The Quiz must be completed in one sitting and you have only one
            attempt.</li>
          <li>You will be asked ten questions and you will be timed.</li>
          <li>Ten points will be awarded for the correct answer.</li>
          <li>
            Each question has four choices and you can choose only one option.
          </li>
          <li>
            You can review and change your answers before you finish the quiz.
          </li>
          <li>The result will be calculated based on your score and time you finish. </li>
          <li>Your result will be displayed at the end of the quiz. </li>
        </ol>
        <form id="form">
          <input
            className="user-id"
            ref={inputRefUser}
            type="text"
            placeholder="Username*"
          />
          <br />
          <br />
          <input
            className="password-id"
            ref={inputRefPassword}
            type="password"
            placeholder="Password*"
          />
          <br />
          <br />
        </form>
        <div className="start">
          <Link className="btn" onClick={startQuiz}>
            Start Quiz
          </Link>
        </div>
        <div className="start">
          <Link className="btn" to={"/pay"}>
            Sign up
          </Link>
        </div>
      </div>
      <footer id="footer">Developed by TNT</footer>
    </div>
  );
}

export default Main;
