import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert2";
import {
  setAllUsers,
  setAuth,
  setPassWord,
  setTrial,
  setUserId,
  setloggedUser,
} from "../redux/resultReducer";
import { useDispatch, useSelector } from "react-redux";
import { CheckUser } from "../Helper/helper";
import { usePublishResult } from "../customHook/SetResult";
import Pay from "./Pay";

function Registration({ newUser, newPassword }) {
  const [user, setUser] = useState([]);
  const [passWord, setMyPassWord] = useState(undefined);

  const auth = useSelector((state) => state.result.auth);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRefUser = useRef(null);
  const inputRefPassword = useRef(null);


  useEffect(() => {
    swal.fire("", "Please don't forget to save your username and passsword", "info")
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/result`)
        .then((res) => res.json())
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            user.push(data[i].username[0]);
          }
        })
        .catch((error) => console.log(error));
    }
    fetchData();
  }, []);

  // console.log(user);
  // const a = "NatnaEL78-";
  // console.log(a.toLowerCase())

  function Register() {
    if (inputRefUser.current?.value && inputRefPassword.current?.value) {
      const newUser = inputRefUser.current?.value;
      const newPassword = inputRefPassword.current?.value;
      // console.log(newUser);
      var value = user.find((e) => e.toLowerCase() === newUser.toLowerCase());
      // console.log(value);

      if (value) {
        // console.log("Yes");
        swal.fire("User already exists", "Try another", "warning");
        return Navigate("/register", { replace: true });
      } else {
        // console.log("No");
        dispatch(
          setAuth([
            inputRefUser.current?.value,
            inputRefPassword.current?.value,
          ])
        );
        dispatch(setloggedUser(auth));
        dispatch(setTrial(1))
        swal.fire("Registration Successful", "Please save your username and password", "info")
        return Navigate("/quiz", { replace: true });
      }
    } else {
      swal.fire("", "Please enter a valid username and password", "warning");
      return Navigate("/register", { replace: true });
    }
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <h1 className="title text-light ">Registration</h1>

        <form id="form">
          <input
            className="user-id"
            type="text"
            ref={inputRefUser}
            placeholder="Username*"
          />
          <br />
          <br />
          <input
            className="password-id"
            type="password"
            ref={inputRefPassword}
            placeholder="Password*"
          />
          <br />
          <br />
        </form>
        <div className="start reg">
          <Link className="btn" onClick={Register}>
            Register
          </Link>
        </div>
        <div className="start">
          <Link className="btn" to={"/"}>
            Back to Login
          </Link>
        </div>
      </div>
      <footer id="footer">Developed by TNT</footer>
    </div>
  );
}

export default Registration;
